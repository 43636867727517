// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as intlTelInput from 'intl-tel-input';


document.addEventListener("turbolinks:load", () => {
    window.intlTelInputGlobals.loadUtils("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js");
    
    let tel = document.querySelector("#user_telephone")
    const iti = intlTelInput(tel, {
        initialCountry: 'gn',
        separateDialCode: true,
        autoPlaceholder: 'polite',
        nationalMode: false,
        formatOnDisplay: false,
        customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            return "Ex: " + selectedCountryPlaceholder;
        },
    })
    if (tel.value !== '') {
        tel.value = tel.value.replace(/\s/g, '')
    }

    let form = $('form');

    $(form).submit(function(e){
        if(this.checkValidity() == false) {
            $(this).addClass('was-validated');
            e.preventDefault();
            e.stopPropagation();
            if (tel.value === '') {
                $('#tel-inv-feedback').show()
            }
        }
    });

    // On every :input focusout validate if empty
    $(':input').blur(function(){
    	let fieldType = this.type;
    	switch(fieldType){
            case 'tel':
                validateTelephone($(this), iti);
                break;
    		case 'password':
                validatePassword($(this));
                break;
    		default:
	    		break;
    	}
    });
    
    // On every :input telephone focusin remove existing validation messages if any
    $('#user_telephone').click(function(){
        let tele = document.querySelector("#user_telephone")
        $(this).removeClass('is-valid is-invalid');
        $('#tel-inv-feedback').hide()
        tele.setCustomValidity('')
    });
    
    // On every :input telephone focusin remove existing validation messages if any
    $('#user_telephone').keydown(function(){
        let tele = document.querySelector("#user_telephone")
        $(this).removeClass('is-valid is-invalid');
        $('#tel-inv-feedback').hide()
        tele.setCustomValidity('')
    });

    // On every :input password focusin remove existing validation messages if any
    $(':password').click(function(){
        let pass = document.querySelector("#user_password")
        $(this).removeClass('is-valid is-invalid');
        pass.setCustomValidity('')
    });
    
    // On every :input password focusin remove existing validation messages if any
    $(':password').keydown(function(){
        let pass = document.querySelector("#user_password")
        $(this).removeClass('is-valid is-invalid');
        pass.setCustomValidity('')
    });

    // Password toggle button
    $(".toggle-password").mousedown(function() {
        $(this.children[0]).toggleClass("fa-eye fa-eye-slash")
        var input = $($(this).attr("toggle"))
        if (input.attr("type") == "password") {
          input.attr("type", "text")
        } else {
          input.attr("type", "password")
        }
    })

    $(".toggle-password").mouseup(function() {
        $(this.children[0]).toggleClass("fa-eye fa-eye-slash");
        var input = $($(this).attr("toggle"))
        if (input.attr("type") == "password") {
          input.attr("type", "text")
        } else {
          input.attr("type", "password")
        }
    })
})

// Validate telephone
function validateTelephone(thisObj, iti) {
    let fieldValue = thisObj.val();
    let tele = document.querySelector("#user_telephone")
    if (fieldValue !== '') {
        thisObj.val(fieldValue.replace(/\s/g, ''))
        
        if (thisObj.val()[0] === "+" && thisObj.val().slice(1,4) === '224') {
            thisObj.val(thisObj.val().slice(4))
        } 
    
        if (thisObj.val().slice(0,2) !== "61") {
            if(iti.isValidNumber()) {
                thisObj.addClass('is-valid')
                $('#tel-inv-feedback').hide()
                $("#user_code_pays").val(iti.getSelectedCountryData().dialCode)
                tele.setCustomValidity('')
            } else {
                thisObj.addClass('is-invalid')
                $('#tel-inv-feedback').show()
                $("#user_code_pays").val('')
                tele.setCustomValidity('Invalid!')
            }
        }else{
            const old_number = thisObj.val()
            thisObj.val(`620${thisObj.val().slice(3)}`)
            if(iti.isValidNumber()) {
                thisObj.addClass('is-valid')
                thisObj.val(old_number) 
                $('#tel-inv-feedback').hide()
                $("#user_code_pays").val(iti.getSelectedCountryData().dialCode)
                tele.setCustomValidity('')
            } else {
                thisObj.addClass('is-invalid')
                thisObj.val(old_number)
                $('#tel-inv-feedback').show()
                $("#user_code_pays").val('')
                tele.setCustomValidity('Invalid!')
            }
        }

    } else {
        thisObj.addClass('is-invalid')
        $('#tel-inv-feedback').show()
        tele.setCustomValidity('Invalid!')
    }
}

// Validate password
function validatePassword(thisObj) {
    let fieldValue = thisObj.val();
    let pass = document.querySelector("#user_password")
    if(fieldValue.length > 5) {
        $(thisObj).addClass('is-valid');
        pass.setCustomValidity('')
    } else {
        $(thisObj).addClass('is-invalid');
        pass.setCustomValidity('Invalid!')
    }
}